<template>
  <v-scale-transition>
    <panel
      :title="title"
    >
      <v-toolbar flat>
        <v-toolbar-title>X-Force Locations</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-spacer />
        <v-text-field
          v-model="searchLocation"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
        <v-dialog
          v-model="deleteLocationDialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Delete Location</span>
            </v-card-title>
            <v-alert
              :value="true"
              type="warning"
            >
              This action is irreversible!
              Are you sure you want to delete X-Force location <strong>{{ editedLocation.location }}</strong>?
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                flat
                @click="deleteXForceLocation"
              >
                Delete Location
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="closeAllDialogs(false)"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="locationDialog"
          max-width="500px"
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="ml-4 mb-2"
              v-on="on"
            >
              Add Location
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="locationForm"
                v-model="validLocation"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-switch
                        v-model="editedLocation.enable"
                        label="Enable"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedLocation.location"
                        label="Location"
                        :rules="[val => !!val || 'Location required!']"
                        validate-on-blur
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        v-model="editedLocation.address"
                        label="Address"
                        rows="3"
                        :rules="[val => !!val || 'Address required!']"
                        validate-on-blur
                        auto-grow
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="editedLocation.state"
                        label="State"
                        :items="provinces"
                        :rules="[val => !!val || 'State required!']"
                        validate-on-blur
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedLocation.leadDealer"
                        label="Lead dealer"
                        :rules="[val => !!val || 'Lead dealer required!']"
                        validate-on-blur
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedLocation.pic"
                        label="PIC"
                        :rules="[val => !!val || 'Person-in-charge required!']"
                        validate-on-blur
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedLocation.contact"
                        label="Contact number"
                        :rules="[val => !!val || 'Contact number required!']"
                        validate-on-blur
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedLocation.kpi"
                        label="KPI"
                        :rules="[val => !!val || 'KPI required!']"
                        validate-on-blur
                        required
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        v-model="editedLocation.remark"
                        label="Remark"
                        rows="3"
                        auto-grow
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>

            <v-alert
              v-model="alert"
              type="error"
            >
              {{ alertMessage }}
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                flat
                @click="closeAllDialogs(false)"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="saveLocation"
              >
                {{ formTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="locationHeaders"
        :items="locationItems"
        :loading="loading"
        :search="searchLocation"
        :expand="expand"
        item-key="location"
        class="elevation-1"
      >
        <template #items="props">
          <tr
            :active="props.selected"
            @click="toggleSelection(props)"
          >
            <td class="text-xs-center">
              <v-icon
                :color="props.item.enable ? 'success' : 'error'"
                black
              >
                {{ props.item.enable ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </td>
            <td class="text-xs-left">
              {{ props.item.location }}
            </td>
            <td class="text-xs-left">
              {{ props.item.address }}
            </td>
            <td class="text-xs-left">
              {{ props.item.state }}
            </td>
            <td class="text-xs-left">
              {{ props.item.leadDealer }}
            </td>
            <td class="text-xs-left">
              {{ props.item.pic }}
            </td>
            <td class="text-xs-left">
              {{ props.item.contact }}
            </td>
            <td class="text-xs-right">
              {{ props.item.kpi }}
            </td>
            <td class="text-xs-left">
              {{ props.item.uplead }}
            </td>
            <td class="text-xs-left">
              {{ props.item.remark }}
            </td>
            <td class="justify-center layout px-0">
              <v-icon
                small
                class="mr-2"
                color="blue"
                @click="updateLocation(props.item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="red lighten-2"
                @click="deleteLocation(props.item)"
              >
                mdi-trash-can-outline
              </v-icon>
            </td>
          </tr>
        </template>
        <template #expand="props">
          <v-layout
            row
            wrap
          >
            <v-flex
              xs3
              offset-xs6
            >
              <v-text-field
                v-model="searchDealer"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-flex>
            <v-flex xs2>
              <v-dialog
                v-model="dealerDialog"
                max-width="500px"
              >
                <template #activator="{ on }">
                  <v-btn
                    color="primary"
                    dark
                    class="ml-4 mb-2"
                    v-on="on"
                  >
                    Assign Dealer
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Assign Dealer</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form
                      ref="dealerForm"
                      v-model="validDealer"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-text-field
                              v-model="assignedDealer"
                              label="Dealer"
                              :rules="[val => !!val || 'Dealer required!']"
                              validate-on-blur
                            />
                          </v-flex>
                          <v-flex xs12>
                            <v-select
                              v-model="workingType"
                              label="Working type"
                              :items="['Full-time', 'Part-time']"
                              :rules="[val => !!val || 'Working type required!']"
                              validate-on-blur
                            />
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-alert
                    v-model="alert"
                    type="error"
                  >
                    {{ alertMessage }}
                  </v-alert>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      flat
                      @click="closeAllDialogs(false)"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      flat
                      @click="assignDealer(props.item.code)"
                    >
                      Assign Dealer
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
            <v-flex
              xs10
              offset-xs1
            >
              <v-data-table
                :headers="dealerHeaders"
                :items="props.item.dealer"
                :search="searchDealer"
                item-key="dealer"
                class="elevation-1"
              >
                <template #items="dealerProps">
                  <tr>
                    <td class="text-xs-left">
                      {{ dealerProps.item.dealer }}
                    </td>
                    <td class="text-xs-left">
                      {{ dealerProps.item.name }}
                    </td>
                    <td class="text-xs-left">
                      {{ dealerProps.item.rank }}
                    </td>
                    <td class="text-xs-center">
                      <v-chip
                        :color="dealerProps.item.workingType === 'Full-time' ? 'success' : 'success darken-3'"
                        text-color="white"
                      >
                        {{ dealerProps.item.workingType }}
                      </v-chip>
                    </td>
                    <td class="text-xs-center">
                      <v-chip
                        :color="dealerProps.item.status === 'Active' ? 'success' : (dealerProps.item.status === 'Demoted' ? 'warning' : 'error')"
                        text-color="white"
                      >
                        {{ dealerProps.item.status }}
                      </v-chip>
                    </td>
                    <td class="justify-center layout px-0">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon
                            small
                            color="orange"
                            v-on="on"
                            @click="removeDealer(props.item, dealerProps.item)"
                          >
                            mdi-account-minus-outline
                          </v-icon>
                        </template>
                        <span>Remove dealer</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </template>
        <template #no-data>
          <v-btn
            color="primary"
            @click="getXForceLocations"
          >
            Load X-Force Locations
          </v-btn>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        color="success"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="snackbarError"
        color="error"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbarError = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'

const pageTitle = 'X-Force Administration'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      title: pageTitle,
      locationItems: [],
      loading: false,
      deleteLocationDialog: false,
      locationDialog: false,
      dealerDialog: false,
      locationHeaders: [
        {
          text: 'Enable',
          align: 'center',
          sortable: true,
          value: 'enable',
        },
        {
          text: 'Location',
          align: 'left',
          sortable: true,
          value: 'location',
        },
        {
          text: 'Address',
          align: 'left',
          sortable: false,
          value: 'address',
        },
        {
          text: 'State',
          align: 'left',
          sortable: true,
          value: 'state',
        },
        {
          text: 'Lead Dealer',
          align: 'left',
          sortable: true,
          value: 'leadDealer',
        },
        {
          text: 'PIC',
          align: 'left',
          sortable: true,
          value: 'pic',
        },
        {
          text: 'Contact #',
          align: 'left',
          sortable: true,
          value: 'contact',
        },
        {
          text: 'KPI',
          align: 'right',
          sortable: true,
          value: 'kpi',
        },
        {
          text: 'AD/MD',
          align: 'left',
          sortable: true,
          value: 'uplead',
        },
        {
          text: 'Remark',
          align: 'left',
          sortable: true,
          value: 'remark',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'location',
        },
      ],
      dealerHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Rank',
          align: 'left',
          sortable: true,
          value: 'rank',
        },
        {
          text: 'Type',
          align: 'center',
          sortable: true,
          value: 'workingType',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'dealer',
        },
      ],
      editedLocationIndex: -1,
      editedLocation: {
        code: null,
        enable: true,
        location: null,
        address: null,
        state: null,
        leadDealer: null,
        pic: null,
        contact: null,
        kpi: null,
        remark: null,
      },
      defaultItem: {
        code: null,
        enable: true,
        location: null,
        address: null,
        state: null,
        leadDealer: null,
        pic: null,
        contact: null,
        kpi: null,
        remark: null,
      },
      expand: false,
      selected: [],
      alert: false,
      alertMessage: null,
      snackbar: false,
      snackbarError: false,
      snackbarText: null,
      validLocation: true,
      validDealer: true,
      provinces: [],
      searchLocation: null,
      searchDealer: null,
      assignedDealer: null,
      workingType: null,
    }
  },

  computed: {
    formTitle () {
      return this.editedLocationIndex === -1 ? 'Add Location' : 'Update Location'
    },
  },

  watch: {
    locationDialog (val) {
      if (val) {
        this.$refs.locationForm.resetValidation()
      } else {
        this.closeAllDialogs(false)
      }
    },
    dealerDialog (val) {
      if (val) {
        this.$refs.dealerForm.reset()
      } else {
        this.alert = false
      }
    },
    deleteLocationDialog (val) {
      val || this.closeAllDialogs(false)
    },
  },

  created: function () {
    this.getXForceLocations()
    this.getProvinces()
  },

  methods: {
    getProvinces () {
      const params = createGetParams({})
      this.$rest.get('getProvinces.php', params)
        .then((response) => {
          this.provinces = response.data
        }).catch(error => {
          console.log(error)
        })
    },

    getXForceLocations () {
      const params = createGetParams({})
      this.loading = true
      this.$rest.get('getXForceLocations.php', params)
        .then((response) => {
          this.locationItems = response.data
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    updateLocation (location) {
      this.editedLocationIndex = this.locationItems.indexOf(location)
      this.editedLocation = Object.assign({}, location)
      this.locationDialog = true
    },

    deleteLocation (location) {
      this.editedLocationIndex = this.locationItems.indexOf(location)
      this.editedLocation = Object.assign({}, location)
      this.deleteLocationDialog = true
    },

    removeDealer (location, dealer) {
      const selectedLocationIndex = this.locationItems.indexOf(location)
      const deletedDealerIndex = this.locationItems[selectedLocationIndex].dealer.indexOf(dealer)
      this.removeXForceDealer(selectedLocationIndex, deletedDealerIndex, dealer.dealer)
    },

    closeAllDialogs (save) {
      this.locationDialog = false
      this.deleteLocationDialog = false
      this.dealerDialog = false
      this.alert = false
      setTimeout(() => {
        this.editedLocation = Object.assign({}, this.defaultItem)
        this.editedLocationIndex = -1
        save && this.getXForceLocations()
      }, 300)
    },

    async deleteXForceLocation () {
      try {
        const params = createPostParams({
          action: 'deleteXForceLocation',
          payload: {
            code: this.editedLocation.code,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.snackbarText = 'You have successfully deleted an X-Force location.'
        this.snackbar = true
        this.closeAllDialogs(true)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    async removeXForceDealer (locationIndex, dealerIndex, dealer) {
      try {
        const params = createPostParams({
          action: 'removeXForceDealer',
          payload: {
            code: this.locationItems[locationIndex].code,
            dealer,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.locationItems[locationIndex].dealer.splice(dealerIndex, 1)
        this.snackbarText = 'You have successfully removed an X-Force dealer from a location.'
        this.snackbar = true
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.snackbarText = message
        this.snackbarError = true
      }
    },

    async saveLocation () {
      if (!this.$refs.locationForm.validate()) {
        return
      }

      try {
        this.editedLocation.leadDealer = this.editedLocation.leadDealer.toUpperCase()
        const params = createPostParams({
          action: this.editedLocationIndex === -1 ? 'addXForceLocation' : 'updateXForceLocation',
          payload: {
            address: this.editedLocation.address,
            code: this.editedLocation.code,
            contact: this.editedLocation.contact,
            enable: this.editedLocation.enable,
            kpi: this.editedLocation.kpi,
            leadDealer: this.editedLocation.leadDealer,
            location: this.editedLocation.location,
            pic: this.editedLocation.pic,
            remark: this.editedLocation.remark,
            state: this.editedLocation.state,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.snackbarText = this.editedLocationIndex === -1
          ? 'You have successfully added an X-Force location.'
          : 'You have successfully updated an X-Force location.'
        this.snackbar = true
        this.closeAllDialogs(true)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    async assignDealer (code) {
      if (!this.$refs.dealerForm.validate()) {
        return
      }

      try {
        const params = createPostParams({
          action: 'assignXForceDealer',
          payload: {
            code,
            dealer: this.assignedDealer.toUpperCase(),
            workingType: this.workingType,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.snackbarText = 'You have successfully assigned a dealer to an X-Force location.'
        this.snackbar = true
        this.closeAllDialogs(true)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    toggleSelection: function (item) {
      this.searchDealer = null
      item.selected = !item.selected
      item.expanded = !item.expanded
      if (this.selected.length > 1) this.selected.shift()
    },

    showError (message) {
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
